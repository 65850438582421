(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('DefaultController', ['$scope', 'ENV', 'GetPageController', '$state', '$stateParams', '$timeout', DefaultController]);

        function DefaultController($scope, ENV, GetPageController, $state, $stateParams, $timeout) {

            var vm = this;
            GetPageController.getPage(vm);

            /********** VARIABLE DECLARATION **********/



            /********** FUNCTION DECLARATION **********/


        }
})();