(function() {

    'use strict';

    /*
     * The user session is only valid for 1 hour, after that time the user is logged out. This service intercepts a
     * HTTP request where the Authorization is null, meaning the token had expired. It then puts the original
     * request on hold, makes a call to the API to refresh the token and if successful, resumes the original request
     * with the new valid token.
     * */

    angular.module('headlessApp').factory('refreshHttpInterceptor', ['$q', '$window', '$injector', 'ENV', rHI]);

    function rHI($q, $window, $injector, ENV) {

        var reqInterceptor = {
            request: function(response) {
                // check if the authorization token has expired, $auth will not have added it to the request

                if (response.headers['Authorization'] == null) {
                    var sT  = $window.localStorage.getItem('satellizer_token');
                    var $auth = $injector.get('$auth');

                    sT ? response.headers['Authorization'] = 'Bearer ' + sT : null;

                    if (sT && !$auth.isAuthenticated() && response.url.indexOf(ENV.API_URL) != -1 && response.url != ENV.API_URL + ENV.REFRESH_URL) {
                        var apiService = $injector.get('apiService');
                        var deferred = $q.defer();

                        apiService.refresh().then(deferred.resolve, deferred.reject);

                        return deferred.promise.then(function(val) {
                            response.headers['Authorization'] = 'Bearer ' + val.data.token;
                            return response;
                        }, function(val) {
                            // refresh didn't work - remove token and continue original
                            if (val.status && (val.status == 500)) {
                                $auth.removeToken();
                            }
                            return response;
                        });
                    }
                }
                return response;
            }
        };

        return reqInterceptor;
    }

})();