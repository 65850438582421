(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('ActivateController', ['$state', '$http', '$stateParams', '$rootScope', '$auth', 'apiService', 'ENV', ActivateController]);

    function ActivateController($state, $http, $stateParams, $rootScope, $auth, apiService, ENV) {

        var vm = this, fullActivationUrl = ENV.API_URL + ENV.ACTIVATION_URL;

        vm.body = {
            activation_code : $stateParams.hash
        };

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $http.post(fullActivationUrl, vm.body).then(function(response) {
            (response.data && response.data.token) ? $auth.setToken(response.data.token) : null;
            if ($auth.getPayload().first_login) {
                $state.go('root.demo', null, {});
                apiService.refresh();
            } else {
                $state.go('root.home', null, {});
            }
        }, function(data) {
            $state.go('root.home', {});
        });

    }

})();