
/*
   wrapper for vimeo
   ! currently background only works with the fullscreen mode
 */

angular.module("headlessApp")

    .directive("vimeo", ['$rootScope', '$window', function ($rootScope, $window) {
        return {
            restrict:   'AE',
            template:   '<div class="vimeo" ng-class="{ \'vimeo--background\' : background, \'vimeo--fullscreen\' : fullScreen}">' +
                            '<iframe ng-class="{\'showing\' : showVideo}" src="{{ videoId | vimeoBackgroundVideo }}" frameborder="0" allowfullscreen></iframe>' +
                        '</div>',
            replace:    'true',
            transclude: 'true',
            link: function (scope, element, attrs) {

                var iframe = angular.element(element).children('iframe')[0];

                scope.showVideo = false;
                scope.background = attrs.background;
                scope.fullScreen = attrs.fullScreen;
                scope.videoId   = attrs.background ? attrs.videoId + "?background=1&api=1" : attrs.videoId;

                // Handle messages received from the player
                function onMsgReceived(ev) {
                    $rootScope.$broadcast('videoEvent', ev);
                }

                if ($window.addEventListener) {
                    $window.addEventListener('message', onMsgReceived, false);
                } else {
                    $window.attachEvent('onmessage', onMsgReceived, false);
                }

                $rootScope.$on('videoEvent', function (ev, data) {
                    data = JSON.parse(data.data);

                    if (data.event == 'ready') {
                        var obj = {
                            method: 'addEventListener',
                            value: 'playProgress'
                        };

                        iframe && iframe.contentWindow ? iframe.contentWindow.postMessage(JSON.stringify(obj), '*') : null;
                    } else if (data.event == 'playProgress') {
                        scope.showVideo = true;
                        scope.$apply();
                    }
                });
            }
        }
    }])

    .filter('vimeoBackgroundVideo', function ($sce) {
        return function(videoId) {
            return $sce.trustAsResourceUrl('https://player.vimeo.com/video/' + videoId);
        };
    });