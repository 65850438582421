(function () {

    'use strict';

    angular
        .module('headlessApp')
        .controller('DemoController', ['$rootScope', '$scope', '$state', '$stateParams', '$auth', '$timeout', 'ENV', DemoController]);

    function DemoController($rootScope, $scope, $state, $stateParams, $auth, $timeout, ENV) {
        
        /********** VARIABLE DECLARATION **********/

        var vm = this;

        vm.first_name   = $auth.getPayload().first_name;
        vm.lastShowing = false;
        vm.progressInfo = progressInfo;


        /********** FUNCTION DECLARATION **********/

        function progressInfo(val) {

            // we want to check if we are on the last slide, if this is the case - show end button
            // wrapped in $timeout for scoping issues

            if (val.item.count == val.item.index + 1) {
                $timeout(function() {
                    vm.lastShowing = true;
                })
            } else {
                $timeout(function() {
                    vm.lastShowing = false;
                })
            }
        }
    }
})();