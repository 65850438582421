(function () {

    'use strict';

    angular
        .module('headlessApp')
        .controller('HeaderController', ['$rootScope', '$auth', '$http', '$scope', '$q', 'ENV', 'apiService', HeaderController]);

    function HeaderController($rootScope, $auth, $http, $scope, $q, ENV, apiService) {
        
        /********** VARIABLE DECLARATION **********/

        var vm = this;

        vm.menuShowing = false;
        vm.menuExternalShowing = false;
        vm.searchBarShowing = false;

        vm.searchQuery  = '';
        vm.searchResult = null;
        vm.noResults = true;

        vm.canceler = $q.defer();
        vm.resolved = false;
        vm.currentPromise = null;

        vm.lightboxShowing = false;

        vm.apiData   = function() {
            return apiService.data;
        };

        /********** FUNCTION DECLARATION **********/

        vm.showMenu = showMenu;
        vm.hideMenu = hideMenu;
        vm.showExternalMenu = showExternalMenu;
        vm.hideExternalMenu = hideExternalMenu;
        vm.showSearchBar = showSearchBar;
        vm.hideSearchBar = hideSearchBar;
        vm.search   = search;

        function showMenu() {
            vm.menuShowing = true;
        }

        function hideMenu() {
            vm.menuShowing = false;
            vm.searchQuery = '';
            vm.searchResult = null;
        }

        function showExternalMenu() {
            vm.menuExternalShowing = true;
        }

        function hideExternalMenu() {
            vm.menuExternalShowing = false;
        }

        function showSearchBar() {
            vm.searchBarShowing = true;
        }

        function hideSearchBar() {
            vm.searchBarShowing = false;
        }

        function search() {
            vm.canceler.resolve(); // in case we there is still a request pending, we have to resolve the request
            if (vm.searchQuery.length > 2) {
                vm.noResults = false;
                vm.canceler = $q.defer();
                vm.resolved = true;
                $http.post(ENV.API_URL + ENV.SEARCH_URL, { q : vm.searchQuery }, { timeout: vm.canceler.promise}).then(function(data) {
                    vm.searchResult = data.data;
                    vm.searchResult.length == 0 ? vm.noResults = true : null;
                })
            } else {
                vm.searchResult = null;
            }
        }

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            vm.menuShowing = false;
            vm.menuExternalShowing = false;
            vm.searchBarShowing = false;
        });

        $rootScope.$on('lightBoxOpen', function (event, toState, toParams, fromState, fromParams) {
            vm.lightboxShowing = true;
        });

        $rootScope.$on('lightBoxClose', function (event, toState, toParams, fromState, fromParams) {
            vm.lightboxShowing = false;
        });
    }
})();