
/*
 wrapper for vimeo
 ! currently background only works with the fullscreen mode
 */

angular.module("headlessApp")

    .directive("fullimage", ['$rootScope', '$window', function ($rootScope, $window) {
        console.log($window);
        return {
            restrict:   'AE',
            template:  '<img src="{{ imageUrl }}" alt="" class="section__content">',
            replace:    'true',
            transclude: 'true',
            link: function (scope, element, attrs) {

                scope.imageUrl   = attrs.data.replace(/(\.[\w\d_-]+)$/i, '_original$1');
            }
        }
    }]);

