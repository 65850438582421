(function () {

    'use strict';

    /* The main entry file, this file takes care of all the routing */

    angular.module('headlessApp',
        [
            'headless.settings',
            'satellizer',
            'ui.router',
            'vAccordion',
            'ngSanitize',
            'ngAnimate',
            'ngFileUpload',
            'duScroll'
        ])

        .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$authProvider', '$qProvider', 'ENV', stateProvider]);

    function stateProvider($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $authProvider, $qProvider, ENV) {

        // we want the html5mode for routing
        $locationProvider.html5Mode(true);
        $qProvider.errorOnUnhandledRejections(false);

        // didn't want this function here, but had no other way, since you cannot use $location.search here
        // we want to do config based on the url variable 'server'
        function getParameter(paramName) {
            var searchString = window.location.search.substring(1),
                i, val, params = searchString.split("&");

            for (i = 0; i < params.length; i++) {
                val = params[i].split("=");
                if (val[0] == paramName) {
                    return val[1];
                }
            }
            return null;
        }

        var server = getParameter('server');
        !server ? server = 0 : null;

        ENV.SERVER_URL = ENV.SERVERS[server];
        $authProvider.baseUrl = ENV.API_URL = ENV.SERVER_URL + ENV.API_PATH; // we use the BASE_URL to access the API
        $authProvider.loginUrl = ENV.LOGIN_URL;
        $authProvider.signupUrl = ENV.REGISTER_URL;

        // - Redirect to the 404 if any other states are requested other than users
        // - Example redirect from home to home/index would be: $urlRouterProvider.when('/home', '/home/index');
        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get('$state');

            $state.go('p404', null, {
                location: false
            });
        });

        $httpProvider.interceptors.push('refreshHttpInterceptor');

        // check if we are on a touch device - to add class to body for CSS
        // never like these hacks because they can become obsolete over time

        if ('ontouchstart' in window) {
            document.getElementsByTagName('body')[0].className += 'touch'
        }

        $stateProvider

        // homepage == landing page

            .state('landing', {
                url: '?server',
                abstract: true,
                views: {
                    'body': {
                        templateUrl: 'partials/content/landing.html',
                        controller : 'LandingController',
                        controllerAs : 'vm'
                    }
                }
            })

            .state('landing.login', {
                url: '/login?to?hash',
                views: {
                    'landing': {
                        templateUrl: 'partials/content/login.html',
                        controller: 'LoginController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: false     // users are not allowed to visit the login page if they are logged in
            })

            .state('landing.register', {
                url: '/registreer',
                params : {
                    apiCall : '/register'
                },
                views: {
                    'landing': {
                        templateUrl: 'partials/content/register.html',
                        controller: 'RegisterController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: false
            })

            .state('landing.register_success', {
                url: '/registreer-succes',
                views: {
                    'landing': {
                        templateUrl: 'partials/content/register_success.html'
                    }
                },
                authenticate: false
            })

            .state('landing.forgot_password', {
                url: '/wachtwoord-vergeten',
                views: {
                    'landing': {
                        templateUrl: 'partials/content/forgot_password.html',
                        controller: 'ForgotPasswordController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: false
            })

            .state('landing.forgot_password_success', {
                url: '/wachtwoord-vergeten_succes',
                views: {
                    'landing': {
                        templateUrl: 'partials/content/forgot_password_success.html'
                    }
                },
                authenticate: false
            })

            .state('landing.reset_password', {
                url: '/wachtwoord-wijzigen/:token/:email',
                views: {
                    'landing': {
                        templateUrl: 'partials/content/reset_password.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('landing.activation', {
                url: '/activatie/:hash',
                views: {
                    'landing': {
                        templateUrl: 'partials/content/activation.html',
                        controller: 'ActivateController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: false
            })

            .state('root', {
                url: '?server',
                abstract: true,
                views: {
                    'header': {
                        templateUrl: 'partials/includes/header_1.html'
                    },
                    'footer': {
                        templateUrl: 'partials/includes/footer_1.html',
                        controller: 'FooterController',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('root.home', {
                url: '/',
                params: {
                    apiCall: '/home'
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.demo', {
                url: '/demo',
                views: {
                    'body@': {
                        templateUrl: 'partials/content/demo.html',
                        controller: 'DemoController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.news', {
                url: '/nieuws',
                params: {
                    apiCall: '/news'
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.news.item', {
                url: '/:slug',
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.actions', {
                url: '/actie',
                abstract: true
            })

            .state('root.actions.item', {
                url: '/:slug',
                params: {
                    apiCall: '/actions'
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'ActionController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.destinations', {
                url: '/bestemmingen',
                params: {
                    apiCall: '/destinations'
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.destinations.item', {
                url: '/:slug',
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.service', {
                url: '/service',
                params: {
                    apiCall: '/service',
                    className: 'service' // hack for overwriting CSS properties in sections
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'ServiceController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.profile', {
                url: '/profiel',
                params: {
                    apiCall: '/user'
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/content/profile.html',
                        controller: 'ProfileController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.static', {
                url: '/static',
                abstract: true
            })

            .state('root.static.item', {
                url: '/:slug',
                params: {
                    apiCall: '/static'
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('root.preview', {
                url: '/preview',
                abstract: true
            })

            .state('root.preview.item', {
                url: '/:slug',
                params: {
                    apiCall: '/preview'
                },
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })


            .state('root.api', {
                url: '/oauth',
                abstract: true
            })

            .state('root.api.authorize', {
                url: '/authorize',
                views: {
                    'body@': {
                        templateUrl: 'partials/structure/page_default.html',
                        controller: 'AuthorizeController',
                        controllerAs: 'vm'
                    }
                },
                authenticate: true
            })

            .state('p404', {
                location: false,
                views: {
                    'body': {
                        templateUrl: 'partials/content/404.html'
                    }
                }
            });
    }

    angular.module('headlessApp').run(['$rootScope', '$state', '$stateParams', '$timeout', '$location', '$http', '$auth', '$anchorScroll',
        function ($rootScope, $state, $stateParams, $timeout, $location, $http, $auth, $anchorScroll) {

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                //TweenMax ? TweenMax.killAll() : null; // FireFox kill switch for TweenMax animations for non-existing objects

                // to login through URL variable:
                // $location.search().login ? $auth.setToken($location.search().login) : null;

                $rootScope.stateChangeScrollStart = document.body.scrollTop;

                var weetenwinurl = '';
                if($auth.isAuthenticated()) {
                    weetenwinurl = $auth.getPayload().weetenwin;
                    if (weetenwinurl.match('jijenklmweetenwin.nl') || weetenwinurl.match('weetenwin.klm-acceptance.com')) {
                        $rootScope.logout();
                    }
                }

                if (toState.authenticate && !$auth.isAuthenticated() && !$auth.getToken()) {
                    $state.go('landing.login', {'to' : $location.path(), 'hash' : $location.hash(), 'server': fromParams.server});
                    event.preventDefault();
                } else if (toState.authenticate === false && $auth.isAuthenticated() && $auth.getToken()) {
                    $state.go('root.home', {'server' : fromParams.server});
                    event.preventDefault();
                }
            });

            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                ga ? ga('send', 'pageview', $location.url()) : null; // google analytics
                //document.body.scrollTop = document.documentElement.scrollTop = 0; // reset scroll to top

                if ($location.hash()) {
                    $timeout(function() {
                        $anchorScroll();
                    }, 1000);
                }

            });

            $rootScope.logout = function() {
                $auth.logout();
                $state.go('landing.login', {'server': $stateParams.server});
                return false;
            };

            svg4everybody(); // this provides svg support for lower Internet Explorer versions (polyfill)

            // if ('addEventListener' in document) { // polyfill to remove 300ms input lag on phone / tablet
            //     document.addEventListener('DOMContentLoaded', function() {
            //         FastClick.attach(document.body);
            //     }, false);
            // }

            window.viewportUnitsBuggyfill.init();


        }]);

})();
