(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('ProfileController', ['$scope', 'ENV', 'GetPageController', '$rootScope', '$document', '$http', 'Upload', '$timeout', 'apiService', ProfileController]);

    function ProfileController($scope, ENV, GetPageController, $rootScope, $document, $http, Upload, $timeout, apiService) {

        var vm = this;

        GetPageController.getPage(vm);

        vm.postdata = {};
        vm.status = '';
        vm.error = {};
        vm.send = send;
        vm.logout = logout;
        vm.change_password_send = change_password_send;

        vm.profile_page = true;
        vm.change_password_success = null;

        $scope.$watch('vm.data', function() {
            // when the data from the backend comes in, we copy it to prepare it for posting data
            if (vm.data) {
                vm.postdata = angular.copy(vm.data.user);
                vm.postdata['tifs'] = [];
                angular.forEach(vm.data.user['tifs'], function (item) {
                    vm.postdata['tifs'][item.id] = item.id;
                });
                vm.postdata['newsletter'] = String(vm.postdata['newsletter']);
            }
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////

        function send() {
            vm.error = "";
            vm.status= "";

            var nArr = [];
            angular.forEach(vm.postdata.tifs, function(item) {
                if (item != null) nArr.push(item);
            });

            var obj = angular.copy(vm.postdata);
            obj.tifs = nArr;
            obj.date_of_birth = obj.date_of_birth_day + "-" + obj.date_of_birth_month + "-" + obj.date_of_birth_year;

            $http.post(ENV.API_URL + ENV.PROFILE_UPDATE_URL, obj).then(function(data) {
                apiService.refresh(); // to get the profile name again
                $document.scrollTop(0, 1000, function (t) { return t<.5 ? 7*t*t*t*t : 1-7*(--t)*t*t*t});
                $timeout(function() {
                    vm.status = 'success';
                }, 600);
                $timeout(function() {
                    vm.status = '';
                }, 5000);
            }, function(response) {
                vm.error = response.data;
                vm.status = 'error';
            }).finally(function() {

            });
        }

        function change_password_send() {
            var obj = {
                email : vm.postdata.email
            };

            vm.change_password_success = false;

            $http.post(ENV.API_URL + ENV.FORGOT_PASSWORD_URL, obj).then(function(data) {
                vm.change_password_success = true;
            }, function(response) {
                vm.change_password_success = false;
            }).finally(function() {

            });
        }

        $scope.upload = function (file) {
            Upload.upload({
                url: ENV.API_URL + ENV.UPLOAD_PROFILE_IMAGE_URL,
                data: {
                    profile_image: file
                }
            }).then(function (response) {
                $timeout(function () {
                    vm.postdata['profile_image'] = response.data.profile_image;
                });
            }, function (response) {
                if (response.status > 0) $scope.errorMsg = response.status
                    + ': ' + response.data;
            }, function (evt) {
                $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
            });
        }

        function logout() {
            $rootScope.logout();
        }
    }

})();