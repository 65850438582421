(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('ServiceController', ['$scope', 'ENV', 'GetPageController', '$state', '$stateParams', '$http', ServiceController]);

    function ServiceController($scope, ENV, GetPageController, $state, $stateParams, $http) {

        var vm = this;

        GetPageController.getPage(vm);

        vm.postdata = {};
        vm.status = null;
        vm.error = {};

        vm.question_send = question_send;

        // $scope.$watch('vm.data', function() {
        //     // when the data from the backend comes in, we copy it to prepare it for posting data
        //     if (vm.data) {
        //         vm.postdata = angular.copy(vm.data.user);
        //         vm.postdata['tifs'] = [];
        //         angular.forEach(vm.data.user['tifs'], function (item) {
        //             vm.postdata['tifs'][item.id] = item.id;
        //         });
        //     }
        // });

        ////////////////////////////////////////////////////////////////////////////////////////////////

        function question_send() {
            vm.error = "";
            vm.status= "";

            $http.post(ENV.API_URL + ENV.CONTACT_URL, vm.postdata).then(function(data) {
                vm.status = 'success';
            }, function(response) {
                vm.status = 'error';
                vm.error = response.data;
                //vm.error = response.data.email.error_messages.toString();
            }).finally(function() {

            });
        }

    }

})();