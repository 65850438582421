(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('ForgotPasswordController', ['$http', '$state', 'ENV', ForgotPasswordController]);

    function ForgotPasswordController($http, $state, ENV) {

        var vm = this;

        vm.postdata = {};
        vm.status = null;
        vm.error = {};
        vm.send = send;

        ////////////////////////////////////////////////////////////////////////////////////////////////

        function send() {
            vm.error = "";
            vm.status= "";

            $http.post(ENV.API_URL + ENV.FORGOT_PASSWORD_URL, vm.postdata).then(function(data) {
                vm.status = 'success';
                $state.go('landing.forgot_password_success');
            }, function(response) {
                vm.status = 'error';
                vm.error = response.data;
                //vm.error = response.data.email.error_messages.toString();
            }).finally(function() {

            });
        }

    }

})();