(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('LandingController', ['$auth', '$scope', '$rootScope', '$window', '$state', 'apiService', 'ENV', LandingController]);

    function LandingController($auth, $scope, $rootScope, $window, $state, apiService, ENV) {
        var vm = this;

        vm.touchdevice = 'ontouchstart' in window;
    }

})();