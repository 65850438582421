(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('ActionController', ['$scope', 'ENV', 'GetPageController', '$state', '$stateParams', '$http', ActionController]);

    function ActionController($scope, ENV, GetPageController, $state, $stateParams, $http) {

        var vm = this;

        GetPageController.getPage(vm);
        vm.postdata = {};
        vm.action_signup_url = null;
        vm.action_signdown_url = null;
        vm.action_signup = action_signup;
        vm.action_signdown = action_signdown;
        vm.action_signup_success = false;
        vm.action_signdown_success = false;
        vm.action_signup_error = false;
        vm.error = {}; // will contain error model
        vm.status = '';

        vm.wincode_submit = wincode_submit;
        vm.wincode_restart = wincode_restart;
        vm.wincode_submit_url = null;

        vm.wincode_result = null;
        vm.submitting = false;

        ////////////////////////////////////////////////////////////////////////////////////////////////

        function action_signup() {
            vm.action_signup_error = false;
            $http.post(vm.action_signup_url, vm.postdata).then(function(response) {
                vm.action_signup_success = true;
            }, function(data) {
                if(data.status == 422) {
                    var reobj = data.data;
                    vm.error = reobj;
                    console.log(reobj);
                } else {
                    vm.action_signup_error     = true;
                }
            }).finally(function() {

            });
        }

        function action_signdown() {
            vm.action_signup_error = false;
            $http.post(vm.action_signdown_url).then(function(response) {
                vm.action_signdown_success = true;
            }, function(response) {
                vm.action_signup_error     = true;
            }).finally(function() {

            });
        }

        function wincode_submit() {
            vm.action_signup_error = false;
            vm.submitting = true;

            $http.post(vm.wincode_submit_url, vm.postdata).then(function(response) {
                vm.wincode_result = response.data;
                console.log(response.data);
            }, function(response) {
                vm.action_signup_error     = true;
                vm.submitting = false;
                console.log('fail: ', response);
            }).finally(function() {

            });
        }

        function wincode_restart() {
            vm.wincode_result = null;
            vm.submitting = false;
        }
    }

})();