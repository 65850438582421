(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('ResetPasswordController', ['$http', '$state', '$stateParams', '$auth', 'ENV', ResetPasswordController]);

    function ResetPasswordController($http, $state, $stateParams, $auth, ENV) {

        var vm = this;

        vm.postdata = {};
        vm.status = null;
        vm.error = null;
        vm.send = send;

        ////////////////////////////////////////////////////////////////////////////////////////////////

        function send() {
            vm.error = "";
            vm.status= "";

            vm.postdata.email = $stateParams.email;
            vm.postdata.token = $stateParams.token;

            $http.post(ENV.API_URL + ENV.RESET_PASSWORD_URL, vm.postdata).then(function(response) {
                (response.data && response.data.token) ? $auth.setToken(response.data.token) : null;
                $state.go('root.home');
            }, function(response) {
                vm.status = 'error';
                vm.error = response.data;
            }).finally(function() {

            });
        }

    }

})();