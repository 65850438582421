(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('AuthorizeController', ['$http', '$window', '$auth', '$timeout', '$state', 'apiService', 'ENV', AuthorizeController]);

    function AuthorizeController($http, $window, $auth, $timeout, $state, apiService, ENV) {
        var vm = this;

        var query = "?" + window.location.search.substring(1) + "&token=" + $auth.getToken();
        var redirect_url = ENV.SERVER_URL + ENV.PUBLIC_API_PATH + ENV.PUBLIC_API_AUTHORIZE_URL + query;

        $window.location.href = redirect_url;
    }

})();