
angular.module("headlessApp")

    .directive("photoalbum", ['$rootScope', '$window', '$http', function ($rootScope, $window, $http) {
        return {
            restrict: 'AE',
            templateUrl: '../partials/directives/photo_album.html',
            replace: 'true',
            transclude: 'true',
            link: function (scope, element, attrs) {

                scope.data          = angular.fromJson(attrs.data);
                scope.thumbs        = [];
                scope.lbImg         = null;         // lightbox image
                scope.lbShowing     = false;        // lightbox showing
                scope.showImage     = showImage;
                scope.closeLightbox = closeLightbox;
                scope.currentlyGetting = null;

                for (var i = 0; i < scope.data.last_page;i++) {
                    if (i != 0) {
                        scope.thumbs.push({items:[]});
                    } else {
                        scope.thumbs.push({items:angular.copy(scope.data.data)});
                    }
                }

                $(element).on('changed.owl.carousel',function(e) {
                    var newPage = e.page.index;
                    if (newPage >= 0) {
                        if (scope.thumbs[newPage].items.length == 0) {
                            getAlbumPage(newPage);
                        }
                    }
                });

                function getAlbumPage(nr) {
                    $http.get(scope.data.album_url + "?page=" + (nr+1))
                        .then(function(response) {
                            scope.thumbs[nr].items = angular.copy(response.data.data);
                        }, function(response) {
                            console.log('error');
                        });
                }
                
                function showImage(img) {
                    scope.lbShowing = true;
                    $rootScope.$broadcast('lightBoxOpen');
                    if (img != scope.currentlyGetting) {  // don't fetch doubleclicks
                        scope.currentlyGetting = img;
                        $http.get(img)
                            .then(function(response) {
                                console.log(response.data);
                                //scope.thumbs[nr].items = angular.copy(response.data.data);
                                scope.lbImg = response.data;
                            }, function(response) {
                                console.log('error');
                            });
                    } else {
                    }
                }

                function closeLightbox() {
                    scope.lbImg = null;
                    scope.lbShowing = false;
                    $rootScope.$broadcast('lightBoxClose');
                }
            }
        }
    }]);



angular.module("headlessApp").directive('klmPhotoAlbum', ['$timeout', function ($timeout) {

    return {

        restrict: 'E',

        link: function (scope, element, attrs) {
            var options = scope.$eval($(element).attr('data-options'));

            $timeout(function() {
                //wrapped in timeout to intialize after ng-repeat, check how behaves online?
                $(element).owlCarousel(options);
            });


            scope.data   = angular.fromJson(attrs.data);


            // var to;

            if (options.autoplayHoverPause) {
                $(element).on('mouseleave',function(e) {
                    // to ? $timeout.cancel(to) : null;
                    // to = $timeout(function() {
                    //     $(element).trigger('next.owl.carousel', [options.autoplaySpeed]);
                    // }, options.autoplayTimeout);
                });

                $(element).on('mouseenter',function(e) {
                    // to ? $timeout.cancel(to) : null;
                });
            }
        }
    };
}]);