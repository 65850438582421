
angular.module("headlessApp")

    .directive("comments", ['$rootScope', '$window', '$http', function ($rootScope, $window, $http) {
        return {
            restrict: 'AE',
            templateUrl: '../partials/directives/comments.html',
            replace: 'true',
            transclude: 'true',
            link: function (scope, element, attrs) {

                scope.data   = angular.fromJson(attrs.data);
                scope.awaitingResponse = false;
                scope.vm.postdata = {};
                scope.vm.status = null;
                scope.vm.error = {};
                scope.postdata = [];
                scope.send = send;

                function send() {
                    scope.vm.error = "";
                    scope.vm.status= "";
                    scope.awaitingResponse = true;

                    !scope.vm.comments ? scope.vm.comments = [] : null;

                    $http.post(scope.data.post_comment.post_url, scope.vm.postdata).then(function(response) {
                        scope.vm.status = 'success';
                        !scope.data.comments ? scope.data.comments = [] : null;
                        scope.data.comments.push(response.data);
                        scope.vm.postdata['description'] = '';
                        scope.awaitingResponse = false;
                    }, function(response) {
                        scope.vm.status = 'error';
                        scope.awaitingResponse = false;
                        scope.vm.error = response.data;
                    }).finally(function() {

                    });
                }
            }
        }
    }]);
