(function() {

    'use strict';

    /*
     * Accesses the API to get a page, which will render it out.
     * */

    angular.module('headlessApp').factory('apiService', ['$http', '$rootScope', '$q', '$auth', '$state', 'ENV', apiService]);

    function apiService($http, $rootScope, $q, $auth, $state, ENV) {
        var service = {};

        service.data = {
            payload : $auth.getPayload()
        };

        service.get = function(apiPath) {
            $rootScope.waitingForData = true;

            return $http.get(ENV.API_URL + apiPath)
                .then(function(response) {

                    //console.log('api service got back: ', response);
                    $rootScope.waitingForData = false;

                    if (typeof response.data === 'object') {
                        console.log(response.data);
                        return response;
                    } else {
                        return $q.reject(response.data);
                    }
                }, function(response) {
                    return $q.reject(response.data);
                });
        };

        service.refresh = function() {
            return $http.get(ENV.API_URL + ENV.REFRESH_URL)
                .then(function(response) {
                    //console.log('refresh success - setting token to: ', response.data.token);
                    $auth.setToken(response.data.token);
                    service.data.payload = $auth.getPayload();
                    return $q.resolve(response);
                }, function(response) {
                    //$state.go('landing.login');
                    return $q.reject(response);
                });
        };

        return service;

    }
})();
