angular.module("headlessApp")

    .directive("include", ['$http', '$templateCache', '$compile', function ($http, $templateCache, $compile) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var templateUrl = scope.$eval(attrs.include);
                $http.get(templateUrl, {cache: $templateCache}).then(
                    function (response) {
                        if (response.data.substring(0, 20) != '<!DOCTYPE html><html') {
                            element.replaceWith($compile(response.data)(scope).addClass(attrs.class));
                        } else {
                            // it was just servering the root index.html -
                            console.log('This section type does not exist.');
                        }
                    },
                    function() {
                        console.log('error');
                    }
                );
            }
        }
    }])

    .directive('includeReplace', function() {
        return {
            require: 'ngInclude',
            restrict: 'A',
            link: function(scope, el, attrs) {
                el.html(el.children().html()); // My changes in the line
            }
        };
    })

    .directive('bgImg', ['ENV', function(ENV) {
        return function (scope, element, attrs) {

            attrs.$observe('bgImg', function (value) {

                if (value) {
                    element.css({
                        'background-image': 'url(' + value + ') ',
                        'background-position': 'center center',
                        'background-repeat': 'no-repeat',
                        'background-size': 'cover'
                    });
                }
            });
        }
    }]);
