
(function() {
    'use strict';

    angular.module('headless.settings',[]).constant('ENV', {
        "API_URL": '',          // will be set to current full url of the API
        "SERVER_URL": '',       // will be set to current server url (only used for DEV, based on the ?server=x url parameter)
        "API_PATH": '/api',
        "SERVERS": ['', 'https://jijenklmnl.eu.ngrok.io', 'https://jijenklm.eboost.nl', 'https://jijenklm.klm-acceptance.com', 'https://jijenklm.nl', 'https://30509814.ngrok.io'],
        "ANIM_PAGE" : 1, // if enabled, page transitions are being used
        "LOGIN_URL" : "/login",
        "REGISTER_URL" : "/register",
        "ACTIVATION_URL" : "/activate",
        "FORGOT_PASSWORD_URL" : "/forgot-password",
        "RESET_PASSWORD_URL" : "/reset-password",
        "PROFILE_UPDATE_URL" : "/user",
        "UPLOAD_PROFILE_IMAGE_URL" : "/user/profile_image",
        "CONTACT_URL": "/contact",
        "SEARCH_URL" : "/search",
        "REFRESH_URL" : "/refresh", // url to refresh the token to keep the user logged in
        "PUBLIC_API_PATH" : '/public-api',
        "PUBLIC_API_AUTHORIZE_URL" : "/oauth/authorize"
    })
})();

