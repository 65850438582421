(function() {

    'use strict';

    angular.module('headlessApp').service('GetPageController', ['apiService', '$state', '$stateParams', GetPageController]);

    function GetPageController(apiService, $state, $stateParams) {

        var vm = this;
        vm.getPage = getPage;

        //console.log($state, $stateParams);

        function getPage(ctrl) {
            var apiCall = $stateParams.apiCall;
            $stateParams.slug ? apiCall += '/' + $stateParams.slug : null;

            apiService.get(apiCall)
                .then(function(response) {
                    ctrl.data = response.data;
                    $stateParams.className ? ctrl.pageName = $stateParams.className : null;

                    ctrl.apiData   = function() {
                        return apiService.data; // this is used so the controller can access synced apiData at all times (like payload)
                    };
                });
        };
    }

})();