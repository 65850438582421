angular.module("headlessApp").directive('setFocus', ['$timeout', function($timeout) {
    return {
        link: function(scope, element, attrs) {
            scope.$watch(attrs.setFocus, function(value) {
                if(value) {
                    $timeout(function() {
                        element[0].focus();
                        scope[attrs.setFocus] = false;
                    });
                }
            });
        }
    };
}]);