(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('LoginController', ['$auth', '$scope', '$rootScope', '$timeout', '$state', 'apiService', '$stateParams', '$location', 'ENV', LoginController]);

    function LoginController($auth, $scope, $rootScope, $timeout, $state, apiService, $stateParams, $location, ENV) {
        var vm = this;

        vm.status = '';
        vm.credentials = {};
        vm.login = login;
        vm.error = '';

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function login() {

            vm.error = "";
            vm.status= "";

            $auth.login(vm.credentials).then(function(response) {
                $auth.setToken(response.data.token);
                if ($auth.getPayload() && $auth.getPayload().first_login) {
                    $state.go('root.demo', null, {});
                    apiService.refresh();
                } else {
                    if ($stateParams.to) {
                        $location.path($stateParams.to, true);
                        $location.hash($stateParams.hash, true);
                        $location.search('to', null);
                        $location.search('hash', null);
                    } else {
                        $state.go('root.home', {'server' : $stateParams.server});
                    }

                    $timeout(function() {
                        apiService.refresh(); // sometimes name does not display
                    }, 1000);
                }
            }).catch(function(response) {
                vm.status = 'error';
                vm.error = response.data && response.data.error ? response.data.error : 'Deze inloggegevens zijn onjuist.';
            });
        }
    }

})();