/*
 * This file contains the general page to page transitions of the main ui-view
 */

angular.module("headlessApp")

    .animation('.error-message', ['ENV', function(ENV) {
        var tt  = 0.6666;
        return {
            enter: function(el, done) {
                TweenMax.from(el, tt, {marginTop: -el[0].offsetHeight, opacity: 1,ease: Power2.easeInOut, overwrite:'none', clearProps:"transform", onOverwrite: done, onComplete:done });
            },
            leave: function(el, done) {
                TweenMax.to(el, tt, {marginTop: -el[0].offsetHeight, opacity: 0, ease: Power2.easeInOut, overwrite:'all', onComplete: done });
            }
        }
    }]);

