(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('FooterController', ['ENV', FooterController]);

        function FooterController(ENV) {

            var vm = this;

            vm.currentYear = new Date().getFullYear();
        }
})();