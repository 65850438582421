

angular.module("headlessApp").directive("clearFocus", [function () {
    return {
        restrict: 'AE',
        scope: false,
        link: function (scope, el, attr) {

            function getFocus() {
                el.removeClass('has_error');

                if (scope.vm.error) {
                    scope.vm.error[attr.name] ? scope.vm.error[attr.name].error_messages[0] = "" : null;
                    scope.vm.error[attr.name] ? scope.vm.error[attr.name].has_error = false : null;
                } else if (scope.error) {
                    scope.error[attr.name] ? scope.error[attr.name].error_messages[0] = "" : null;
                    scope.error[attr.name] ? scope.error[attr.name].has_error = false : null;
                }
            }

            el.bind('focus', getFocus);
            el.bind('click', getFocus);

            scope.$on('$destroy', function() {
                el.unbind('focus', getFocus);
                el.unbind('click', getFocus)
            });
        }
    }
}]);