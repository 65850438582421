(function() {

    'use strict';

    angular
        .module('headlessApp')
        .controller('RegisterController', ['$scope', '$auth', '$state', 'GetPageController', 'Upload', 'ENV', '$timeout',registerController]);

    function registerController($scope, $auth, $state, GetPageController, Upload, ENV, $timeout) {

        var vm = this;

        GetPageController.getPage(vm);

        vm.postdata = {};
        vm.countries = '';
        vm.data = {};

        vm.send = send;

        vm.error = {}; // will contain error model
        vm.status = '';


        ////////////////////////////////////////////////////////////////////////////////////////////////


        function send() {
            // removing empty values from the tifs array - not very nice putting it here, but too little time.
            var nArr = [];
            angular.forEach(vm.postdata.tifs, function(item) {
                if (item != null) nArr.push(item);
            });

            var obj = angular.copy(vm.postdata);
            obj.tifs = nArr;
            obj.date_of_birth = obj.date_of_birth_day + "-" + obj.date_of_birth_month + "-" + obj.date_of_birth_year;

            // start loader
            $auth.signup(obj).then(function(data) {

                //$state.go('root.view.register.success');

                vm.status = 'success';
                $state.go('landing.register_success');

            }, function(data) {
                console.log('error', data);
                if(data.status == 422) {
                    var reobj = data.data;
                    vm.error = reobj;
                    console.log(reobj);
                    vm.status = 'error';
                } else {
                    //$state.go('root.view.register.error');
                    console.log('error', data);
                }

            }).finally(function() {
                // stop loader
            });

        }

        $scope.upload = function (file) {
            Upload.upload({
                url: ENV.API_URL + ENV.UPLOAD_PROFILE_IMAGE_URL,
                data: {
                    profile_image: file
                }
            }).then(function (response) {
                console.log('success: ', response);
                $timeout(function () {
                    //$scope.result = response.data;
                    vm.postdata['profile_image'] = response.data.profile_image;

                    console.log(vm.postdata);
                });
            }, function (response) {
                console.log('error: ', response);
                if (response.status > 0) $scope.errorMsg = response.status
                    + ': ' + response.data;
            }, function (evt) {
                $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
            });
        }

    }
})();