/*
 * This file contains the general page to page transitions of the main ui-view
 */

angular.module("headlessApp")

    .animation('.landing__ui-view', ['ENV', function(ENV) {
        var tt  = 1;
        var dly = 0.333;
        var stagger = 0.0333;

        return {
            enter: function(el, done) {
                el.addClass('ng-enter');
                function finished() {
                    el.removeClass('ng-enter');
                    done();
                }
                ENV.ANIM_PAGE ? TweenMax.from(el, tt, {transform: "translateX(100vw)", opacity: 1,ease: Power2.easeInOut, overwrite:'none', clearProps:"transform", onOverwrite: finished, onComplete:finished }) : null;
            },
            leave: function(el, done) {
                var sT = Math.round(document.body.scrollTop);

                el.addClass('ng-leave');
                function finished() {
                    el.removeClass('ng-leave');
                    done();
                }

                ENV.ANIM_PAGE ? TweenMax.fromTo(el, tt, { transform: "translateY(-" + sT + "px)" , opacity: 1}, {transform: "translate(-100vw, -" + sT + "px)", opacity: 1, ease: Power2.easeInOut, overwrite:'all', onComplete: finished }) : null;
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            addClass : function(el, className, done) {
            },
            removeClass : function(el, className, done) {
            }
        }
    }]);

