
angular.module("headlessApp")

    // we moeten eerst door het aantal secties heenlopen

    .directive("loadMore", ['$rootScope', '$window', '$compile', '$http', '$timeout', function ($rootScope, $window, $compile, $http, $timeout) {
        return {
            restrict: 'AE',
            template: '<section class="section--load_more section-spacing"><div class="viewport-container"><button class="load_more_btn btn--bordered txt--smooth" ng-click="loadMore()">Toon meer</button></div></section>',
            replace: 'true',
            transclude: 'true',
            link: function (scope, element, attrs) {
                scope.loadMore = loadMore;
                scope.data = '';
                scope.loadmore_data = angular.fromJson(attrs.section);
                scope.waitingForResult = false;

                if (!scope.loadmore_data.fields.next) {
                    // if there is no next field, there is no reason to display load more
                    $timeout(function() {
                        element.replaceWith('');
                    });
                }

                function loadMore() {
                   if (!scope.waitingForResult) {
                       scope.waitingForResult = true; // to block multiple clicks
                       $http.get(scope.loadmore_data.fields.next).then(function(data) {
                           scope.data = data.data;
                           var html = '<div include="\'partials/sections/\' + section.type + \'.html\'" ng-model="section" ng-repeat="section in data.sections" class="section--standard"></div>';
                           var e = $compile(html)(scope);
                           element.replaceWith(e);
                           scope.waitingForResult = false;
                       }, function(response) {
                           console.log('failed to load');
                       }).finally(function() {

                       });
                   }
                }
            }
        }
    }]);