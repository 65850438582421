angular.module("headlessApp").directive('owlCarousel', ['$timeout', function ($timeout) {

    return {

        restrict: 'E',

        link: function (scope, element, attrs) {
            var options = scope.$eval($(element).attr('data-options'));

            $timeout(function() {
                //wrapped in timeout to intialize after ng-repeat, check how behaves online?
                $(element).owlCarousel(options);
            });

            var to;

            if (options.autoplayHoverPause) {
                $(element).on('mouseleave',function(e) {
                    to ? $timeout.cancel(to) : null;
                    to = $timeout(function() {
                        $(element).trigger('next.owl.carousel', [options.autoplaySpeed]);
                    }, options.autoplayTimeout);
                });

                $(element).on('mouseenter',function(e) {
                    to ? $timeout.cancel(to) : null;
                });
            }
        }
    };
}]);